<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card bg-light border-blue">
          <div class="card-body pt-3 pb-3">
            <div class="text-uppercase text-dark text-center font-weight-bold">
              Starter
            </div>
            <div class="text-center text-blue">
              <div class="font-weight-bold font-size-24">Standard Plan</div>
              <div>$0.060 /hour</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pt-3 pb-3">
            <div class="text-uppercase text-dark text-center font-weight-bold">
              Performance
            </div>
            <div class="text-center text-blue">
              <div class="font-weight-bold font-size-24">General Purpose</div>
              <div>$0.060 /hour</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body pt-3 pb-3">
            <div class="text-uppercase text-dark text-center font-weight-bold">
              Pro
            </div>
            <div class="text-center text-blue">
              <div class="font-weight-bold font-size-24">CPU Optimized</div>
              <div>$0.060 /hour</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
